/* eslint-disable max-len */
/* eslint-disable no-undef */
/* eslint-disable no-inner-declarations */
const stackSlider = document.querySelector( '.bs-section--titan-cloud-fills-tab-slider' );

if ( stackSlider ) {
  document.addEventListener( 'readystatechange', () => {
    if ( document.readyState === 'complete' ) {
      setTimeout( () => {
        const windowSize = window.matchMedia( '(min-width: 1200px)' );

        if ( windowSize.matches ) {
          let StackCards = function ( element ) {
            this.element = element;
            this.contentSlideWrapper = this.element
              .closest( '.bs-tab-slider--home-scroll-animation' )
              .querySelectorAll( '.bs-slider-content .slick-slide-wrapper' );
            this.tabSlideWrapper = this.element
              .closest( '.bs-tab-slider--home-scroll-animation' )
              .querySelectorAll( '.bs-slider-tabs .slick-slide-wrapper' );
            this.section = this.element.closest( '.bs-section--titan-cloud-fills-tab-slider' );
            this.scrollingListener = false;
            this.scrolling = false;
            this.cardTop = 300;
            this.cardHeight = 500;
            this.marginY = 30;
            initStackCardsEffect( this );
          };

          let slideWrappers = document.querySelectorAll(
            '.bs-tab-slider--home-scroll-animation .bs-slider-content .slick-slide-wrapper'
          );
          let tabSlideWrappers = document.querySelectorAll(
            '.bs-tab-slider--home-scroll-animation .bs-slider-tabs .slick-slide-wrapper'
          );
          const topHeader = document.querySelector( '.nav' );
          const section = document.querySelector( '.bs-section--titan-cloud-fills-tab-slider' );
          const adminBar = document.querySelector( '#wpadminbar' );
          const headerHeightNew = adminBar
            ? adminBar.offsetHeight + topHeader.offsetHeight + 30
            : topHeader.offsetHeight + 30;
          let initialOffsets = [];

          for ( let h = 0; h < tabSlideWrappers.length; h++ ) {
            let activeTab = tabSlideWrappers[h].getAttribute( 'data-slick-index' );
            if ( activeTab === '0' ) {
              tabSlideWrappers[h].classList.add( 'tab-active' );
            } else {
              tabSlideWrappers[h].classList.remove( 'tab-active' );
            }
          }

          for ( let c = 0; c < slideWrappers.length; c++ ) {
            let activeCard = slideWrappers[c].getAttribute( 'data-slick-index' );
            if ( activeCard === '0' ) {
              slideWrappers[c].classList.add( 'clicked' );
            } else {
              slideWrappers[c].classList.remove( 'clicked' );
            }
          }

          function wrappersOffsets() {
            let sectionOffset = section?.offsetTop;
            slideWrappers.forEach( function ( slideWrapper ) {
              initialOffsets.push( sectionOffset + slideWrapper.offsetTop );
            } );
          }
          wrappersOffsets();

          function stackCardsCallback( entries ) {
            if ( entries[0].isIntersecting ) {
              if ( this.scrollingListener ) return;
              this.section.classList.add( 'in-viewport' );
              topHeader.classList.add( 'in-viewport' );
              stackCardsInitEvent( this );
            } else {
              this.section.classList.remove( 'in-viewport' );
              topHeader.classList.remove( 'in-viewport' );
              if ( this.scrollingListener ) {
                window.removeEventListener( 'scroll', this.scrollingListener );
                this.scrollingListener = false;
              }
            }
          }

          function initStackCardsEffect( element ) {
            let observer = new IntersectionObserver( stackCardsCallback.bind( element ), {} );
            observer.observe( element.element );

            for ( let i = 0; i < element.tabSlideWrapper.length; i++ ) {
              element.tabSlideWrapper[i].addEventListener( 'click', function () {
                handleTabClick.bind( element )( i );
                window.scrollTo( {
                  top: initialOffsets[i] - 67,
                  behavior: 'smooth',
                } );
              } );
            }
          }

          let stackCards = document.querySelectorAll( '.bs-tab-slider--home-scroll-animation .bs-slider-content' ),
            intersectionObserverSupported =
              'IntersectionObserver' in window &&
              'IntersectionObserverEntry' in window &&
              'intersectionRatio' in window.IntersectionObserverEntry.prototype,
            reducedMotion = matchMedia( '(prefers-reduced-motion: reduce)' ).matches;

          if ( stackCards.length > 0 && intersectionObserverSupported && !reducedMotion ) {
            for ( const element of stackCards ) {
              StackCards( element );
            }
          }

          const observer1 = new IntersectionObserver(
            entries => {
              entries.forEach( entry => {
                entry.target.classList.toggle( 'show', entry.isIntersecting );
              } );
            },
            {
              rootMargin: '0px 0px 85%',
              threshold: 0.7, // First threshold value
            }
          );

          function createObserver2() {
            const observer2 = new IntersectionObserver(
              entries => {
                entries.forEach( entry => {
                  entry.target.classList.toggle( 'show', entry.isIntersecting );
                } );
              },
              {
                rootMargin: '0px 0px -100px',
                threshold: 0.8,
              }
            );
            slideWrappers.forEach( card => {
              observer2.observe( card );
            } );
          }

          slideWrappers.forEach( card => {
            observer1.observe( card );
          } );

          let observer2;
          function destroyObserver2() {
            if ( observer2 ) {
              slideWrappers.forEach( card => {
                observer2.unobserve( card );
              } );
              const observer2 = null;
            }
          }

          function stackCardsScrolling() {
            if ( this.scrolling ) return;
            this.scrolling = true;
            window.requestAnimationFrame( animateStackCards.bind( this ) );
          }

          function stackCardsInitEvent( element ) {
            element.scrollingListener = stackCardsScrolling.bind( element );
            window.addEventListener( 'scroll', element.scrollingListener );
          }

          function animateStackCards() {
            let top = this.element.getBoundingClientRect().top;
            for ( let i = 0; i < this.contentSlideWrapper.length; i++ ) {
              let scrolling = this.cardTop - top - i * ( this.cardHeight + this.marginY );
              let dataIndex = this.contentSlideWrapper[i].getAttribute( 'data-slick-index' );
              if ( scrolling > 0 ) {
                this.contentSlideWrapper[i].setAttribute(
                  'style',
                  'transform: scale(' + ( this.cardHeight - scrolling * 0.05 ) / this.cardHeight + ');'
                );
                if ( dataIndex !== null ) {
                  dataIndex = parseInt( dataIndex );
                  for ( let h = 0; h < this.tabSlideWrapper.length; h++ ) {
                    let slideWrapperIndex = this.tabSlideWrapper[h].getAttribute( 'data-slick-index' );
                    if ( parseInt( slideWrapperIndex ) === dataIndex ) {
                      this.tabSlideWrapper[h].classList.add( 'tab-active' );
                      this.contentSlideWrapper[i].classList.add( 'current' );
                    } else {
                      this.tabSlideWrapper[h].classList.remove( 'tab-active' );
                      this.contentSlideWrapper[h].classList.remove( 'current' );
                    }
                  }
                }
                const lastSlide = this.contentSlideWrapper[this.contentSlideWrapper.length - 1];
                let lastTab = document.querySelectorAll(
                  '.bs-section--titan-cloud-fills-tab-slider .bs-tab-slider--home-scroll-animation'
                );
                const beforeLastSlide = this.contentSlideWrapper[this.contentSlideWrapper.length - 2];
                let offsetTopBefore = beforeLastSlide.getBoundingClientRect().top;
                let offsetTopLast = lastSlide.getBoundingClientRect().top;
                const screenWidth = window.innerWidth;
                if ( lastSlide.classList.contains( 'current' ) && offsetTopLast - offsetTopBefore <= 30 ) {
                  document.querySelector( '.bs-tab-slider--home-scroll-animation .bs-slider-tabs' ).style.top = '10vh';
                  lastTab.forEach( tab => tab.classList.add( 'last-tab-active' ) );
                } else {
                  if ( screenWidth > 2000 ) {
                    document.querySelector( '.bs-tab-slider--home-scroll-animation .bs-slider-tabs' ).style.top = '18vh';
                  } else {
                    document.querySelector( '.bs-tab-slider--home-scroll-animation .bs-slider-tabs' ).style.top = `${
                      headerHeightNew - 124
                    }px`;
                    lastTab.forEach( tab => tab.classList.remove( 'last-tab-active' ) );
                  }
                }
              }
            }
            this.scrolling = false;
          }

          function handleTabClick( clickedIndex ) {
            let dataIndex = this.contentSlideWrapper[clickedIndex].getAttribute( 'data-slick-index' );
            if ( dataIndex !== null ) {
              dataIndex = parseInt( dataIndex );
              for ( let h = 0; h < this.tabSlideWrapper.length; h++ ) {
                let slideWrapperIndex = this.tabSlideWrapper[h].getAttribute( 'data-slick-index' );
                if ( parseInt( slideWrapperIndex ) === dataIndex ) {
                  this.tabSlideWrapper[h].classList.add( 'tab-active' );
                  this.contentSlideWrapper[h].classList.add( 'clicked' );
                } else {
                  this.tabSlideWrapper[h].classList.remove( 'tab-active' );
                }
              }
            }
          }

          window.addEventListener( 'resize', () => {
            const screenWidth = window.innerWidth;
            if ( screenWidth >= 2000 ) {
              destroyObserver2();
              createObserver2();
            } else {
              destroyObserver2();
            }
          } );

          const initialScreenWidth = window.innerWidth;
          if ( initialScreenWidth >= 2000 ) {
            createObserver2();
          }
        }
      }, 1000 );
    }
  } );
}
